import axios from "axios";

export const URL = process.env.REACT_APP_SERVER_URL;

const getLang = () => {
  const roles = localStorage.getItem("lang");
  return roles;
};
const instance = axios.create({
  baseURL: `${URL}/api/`,
  headers: {
    "X-Language": localStorage.getItem("lang") ?? localStorage.getItem("lang"),
    Authorization: `Bearer ${localStorage.getItem("id_token")}`,
  },
});

export default instance;
